<template>
  <div class="setting">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">设置</div>
      <div class="rightBtn"></div>
    </div>

    <div class="main">
      <div class="cellBox mb60">
        <div class="cellItem" @click="toBindPhone">
          <div class="cellTitle">手机号码</div>
          <div class="cellContent">{{ phoneVal }}</div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </div>

        <router-link tag="div" to="/retrieveAct" class="cellItem">
          <div class="cellTitle">账号找回</div>
          <div class="cellContent"></div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>

        <div class="cellItem" @click="bindInviteCode">
          <div class="cellTitle">邀请码</div>
          <div class="cellContent">{{ userInfo.inviterCode || "未设置" }}</div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </div>

        <router-link tag="div" to="/commonProblem" class="cellItem">
          <div class="cellTitle">常见问题</div>
          <div class="cellContent"></div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>

        <router-link tag="div" to="/customerService" class="cellItem">
          <div class="cellTitle">在线客服</div>
          <div class="cellContent">24小时在线</div>
          <svg-icon class="cellIcon" iconClass="settingArrow" />
        </router-link>

        <div class="cellItem">
          <div class="cellTitle">商务合作</div>
          <div class="cellContent">{{ businessCooperation }}</div>
          <div class="copyBtn" @click="copyUrl">复制</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { queryUserInfo } from "@/api/user";
export default {
  name: "setting",
  data() {
    return {
      businessCooperation: "",
      userInfo: {},
      phoneVal: "",
    };
  },
  created() {
    let appConfig = this.$store.getters.appConfig;
    appConfig.sourceList.forEach((i) => {
      if (i.type == "BusinessCooperation") {
        this.businessCooperation = i.domain[0].url;
      }
    });
    this.queryUserInfo();
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 复制链接
    copyUrl() {
      this.$copyText(this.businessCooperation).then(
        (e) => {
          Toast("复制成功");
        },
        (e) => {
          Toast("复制失败");
        }
      );
    },
    // 获取用户信息
    async queryUserInfo() {
      let res = await this.$Api(queryUserInfo, { uid: 0 });
      if (res.code === 200) {
        this.userInfo = res.data;
        this.phoneVal = this.handlePhone();
      }
    },
    // 绑定手机
    toBindPhone() {
      // if (this.userInfo.mobile) {
      //    Toast("您已绑定手机号");
      //    return;
      // }
      this.$router.push("/bindPhone");
    },
    // 绑定邀请码
    bindInviteCode() {
      if (this.userInfo.inviterCode) {
        Toast("您已绑定了邀请码");
        return;
      }
      this.$router.push("/inviteCode");
    },
    // 处理手机号
    handlePhone() {
      let phone = this.userInfo.mobile + "";
      let str = "绑定手机";
      if (phone) {
        str =
          phone.substring(0, phone.length - 8) +
          "****" +
          phone.substring(phone.length - 3) +
          " 已绑定";
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.setting {
  height: 100%;
  background: #f5f5f5;
  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    // border-bottom: 1px solid #e6e6e6;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 16px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 12px 10px 0;
    box-sizing: border-box;
    .cellBox {
      padding: 0 12px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;
      box-shadow: -5px -5px 20px 0px #fff,
        5px 5px 20px 0px rgba(174, 174, 192, 0.5);
    }

    .cellItem {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 44px;
      font-size: 14px;
      border-bottom: 1px solid #e6e6e6;
      .cellTitle {
        color: #000;
      }

      .cellContent {
        color: #999999;
        flex: 2;
        text-align: end;
        margin-right: 10px;
        font-size: 12px;
      }

      .cellIcon {
        width: 12px;
        height: 12px;
      }

      .copyBtn {
        width: 50px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 2px solid rgba(0, 0, 0, 0.05);
        background: #f0f0f3;
        box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.1) inset,
          2px 2px 4px 0px #fff inset, -2px -2px 2px 0px rgba(255, 255, 255, 0.4),
          2px 2px 2px 0px rgba(174, 174, 192, 0.2);
        font-size: 12px;
        font-weight: 900;
        color: #b09fd9;
        // background: url("../../../assets/png/copyBtn.png") no-repeat;
        // background-size: 100% 100%;
      }
    }
    .cellItem:last-child {
      border-bottom: none;
    }
  }
}
</style>
